import React from 'react'
import { graphql } from 'gatsby'

import { Billboard, SEO, Button } from '@renderbus/common/components'
import {
  BannerContainer,
  DetailContainer,
  DetailWrapper,
  TitleImg,
  StepsImgBox,
  StepsImgMobileBox,
  RuleWrapper,
} from './guoqing2020.atom'

import JoinTitleImg from '../images/guoqing2020/join-title.png'
import RuleTitleImg from '../images/guoqing2020/rule-title.png'
import StepsImg from '../images/guoqing2020/steps.png'
import StepsMobileImg from '../images/guoqing2020/steps-mb.png'

import Layout from '../molecules/layout'

class Guoqing2020 extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='云渲染活动中心,云渲染充值优惠活动,折扣价格,行业竞赛等-Renderbus云渲染农场'
          keywords='云渲染活动'
          description='Renderbus云渲染活动中心,云渲染充值优惠,折扣价格,行业竞赛等,瑞云渲染农场优惠活动'
        />
        <BannerContainer>
          <Button
            as='a'
            href='https://my.qingjiaocloud.com/billing/recharge?usergwcz'
            target='_blank'
          >
            立即充值
          </Button>
          <Billboard
            fluid={[
              data.bannerMobile.childImageSharp.fluid,
              {
                ...data.banner.childImageSharp.fluid,
                media: `(min-width: 600px)`,
              },
            ]}
          />
        </BannerContainer>
        <DetailContainer>
          <DetailWrapper>
            <TitleImg src={JoinTitleImg} alt='参与方式' />
            <StepsImgBox>
              <img src={StepsImg} alt='参与步骤' />
            </StepsImgBox>
            <StepsImgMobileBox>
              <img src={StepsMobileImg} alt='参与步骤' />
            </StepsImgMobileBox>
            <TitleImg src={RuleTitleImg} alt='活动规则' />
            <RuleWrapper>
              <p>1、本次活动充值金额及赠送代金券仅限青椒云云电脑消费使用；</p>
              <p>2、本次充值福利：充值满50元起，赠送15%代金券（例：充50送7.5，充100送15）；</p>
              <p>
                3、活动时间内完成充值后，赠送代金券实时自动到账，在个人账户-后台优惠券模块可查看；
              </p>
              <p>4、本次充值金额不限期使用，赠送的代金券仅限2020年使用；</p>
              <p>5、本次活动最终解释权归瑞云科技所有。</p>
            </RuleWrapper>
          </DetailWrapper>
        </DetailContainer>
      </Layout>
    )
  }
}

export default Guoqing2020

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/guoqing2020/banner-qingjiaoyun.png/" }) {
      ...fluidImage
    }
    bannerMobile: file(relativePath: { regex: "/guoqing2020/banner-qingjiaoyun-mb.png/" }) {
      ...fluidImage
    }
  }
`
